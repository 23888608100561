import './image-gallery.scss';
import Swiper, { Navigation, Pagination } from 'swiper';

class ImageGallery {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-image-gallery',
            sliderAttr: 'slider',
            slideAttr: 'slide'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$imageGallery = $element;
        this.$imageGalleryContainer = this.$imageGallery.querySelector(`[${this.settings.initAttr}="${this.settings.sliderAttr}"]`);
        this.$imageGallerySlides = this.$imageGallery.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideAttr}"]`);
        this.imageGallerySlider = null;

        if (this.$imageGalleryContainer && this.$imageGallerySlides.length > 1) {
            this.initialize();
        }
    }

    initialize () {
        this.initSlider();
    }

    initSlider () {
        this.imageGallerySlider = new Swiper(this.$imageGalleryContainer, {
            autoHeight: true,
            modules: [Navigation, Pagination],
            speed: 500,
            effect: 'fade',
            slidesPerView: 1,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    }

    destroySlider () {
        if (this.imageGallerySlider !== null) {
            this.imageGallerySlider.destroy(true, true);
            this.imageGallerySlider = null;
        }
    }
}

export { ImageGallery };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$imageGallery = $context.querySelectorAll('[data-image-gallery="root"]');
        for (let i = 0; i < $$imageGallery.length; i++) {
            const $imageGallery = $$imageGallery[i];

            $imageGallery.API = new ImageGallery($imageGallery);
        }
    }
});
